/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    commuter: [],
    commuters: [],
    commutercard: [],
    commutercards:[],
    commuterCardsTransactions:[],
    commuterCardsTransactionsByAssid:[],
    commuter_routes: [],
    cardinventory: [],
    allcardsinventory:[],
    routepricings: [],
    routeprice: [],
    routes: [],
    
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETCOMMUTERBYID: state => state.commuter,
    GETCOMMUTERCARDBYID: state => state.commutercard,
    GETALLCOMMUTERS: state => state.commuters,
    GETALLCOMUTTERCARDS: state => state.commutercards,
    GETCOMMUTERCARDTRANSACTIONS: state => state.commuterCardsTransactions,
    COMMUETRCARDTRANSACTIONSBYASSID: state => state.commuterCardsTransactionsByAssid,
    GETCOMMUTERROUTES: state => state.commuter_routes,
    GETROUTESBYASSID: state => state.routes,
    GETCARDSINVENTORYBYASSID: state => state.allcardsinventory,
    GETROUTEPRICILNGBYASSID: state => state.routepricings,
    GETROUTEPRICEBYID: state => state.routeprice,
    GETCARDBYID: state => state.cardinventory,
    GETALLPROVINCES(state, getters, rootState, rootGetters)
   { 
   return rootGetters['lookups/GETALLPROVINCES'];
  },
  TAXIASSOCIATIONSROUTES(state, getters, rootState, rootGetters)
   { 
   return rootGetters['TA/TAXIASSOCIATIONSROUTES'];
  },

  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commuters = _data;
      }
    },

    COMMUTERLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commuter = _data;
      }
    },

    COMMUTERCARDSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commutercards = _data;
      }
    },
    
    ROUTESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.routes = _data;
      }
    },

    CARDTRANSACTIONDBYASSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commuterCardsTransactionsByAssid = _data;
      }
    },

    COMMUTERCARDLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commutercard = _data;
      }
    },

    COMMUTERROUTESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commuter_routes = _data;
      }
    },

    CARDLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.cardinventory = _data;
      }
    },

    CARDSBYASSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.allcardsinventory = _data;
      }
    },

    COMMUTERCARDTRANSACTIONSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.commuterCardsTransactions = _data;
      }
    },

    ROUTEPRICINGLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.routepricings = _data;
      }
    },

    ROUTEPRICELOADED: (state, _data) => {
      if (_data !== undefined) {
        state.routeprice = _data;
      }
    },


    COMMUTERADDED: (state, _data) => {
      state.commuters.push(_data);
    },
    CARDINVENTORYADDED: (state, _data) => {
      state.allcardsinventory.push(_data);
    },

    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.commuters.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.commuters.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.commuters.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, { id, deletedby }) => {
      if (id !== undefined) {
        state.commuters.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.commuters.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.commuters = state.commuters.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { router } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getByTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERSBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getByTaxiAssId(requestheader,id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getVehicllesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGVEHICLESBYID_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    getOtherContactsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGCONTACTSBYID_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    getSanralTransactionsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGALLTRANSACTIONS_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    getSanralEtollsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGTOLLSBYID_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    getSanralfeesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGFEESBYID_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    getById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERLOADED', parsedResult.data);
            //console.log('Commuter Found: ',parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('Commuter errors: ',error);
          reject(error);
        });
      });
    },
    removeRoutesByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.VIPCARD_REMOVEVIPROUTES_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        
        if (parsedResultOP.status) {
          // commit('ROUTESLOADED', parsedResultOP.data);
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP.data); 
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    getTaxiAssRoutes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.VIPCARD_ROUTESBYASSID_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        
        if (parsedResultOP.status) {
          // commit('ROUTESLOADED', parsedResultOP.data);
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP.data); 
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getVehiclesByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { taxiassociationid,skip,len,router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SANRALETAGVEHICLESBYASSID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {status:taxiassociationid,skip,len}).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERCARDSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    blockeTagCard({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { AddModel, router }) {
          return new Promise((resolve, reject) => {
            
            const requestheader = {
    
              router,
              token: rootGetters['auth/TOKEN'],
              apiName: config.SANRALETAGBLOCKCARD_URL,
            };
    
            this._vm.$TAMS_API.add(requestheader,
              AddModel).then((parsedResult) => {
              if (parsedResult.status) { 
                console.log('block card : ', parsedResult.data);
                // commit('CARDINVENTORYADDED', parsedResult.data);
                resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
              }
            }).catch((error) => {
              console.log('block card errors: ', error);
            // commit('LOGINFAILED', error.data);
              reject(error);
            });
          });
        },
        unblockeTagCard({
              state, rootState, rootGetters, commit, dispatch, getters,
            }, { AddModel, router }) {
              return new Promise((resolve, reject) => {
                
                const requestheader = {
        
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.SANRALETAGUNBLOCKCARD_URL,
                };
        
                this._vm.$TAMS_API.add(requestheader,
                  AddModel).then((parsedResult) => {
                  if (parsedResult.status) { 
                    console.log('unblock card : ', parsedResult.data);
                    // commit('CARDINVENTORYADDED', parsedResult.data);
                    resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                  console.log('unblock card errors: ', error);
                // commit('LOGINFAILED', error.data);
                  reject(error);
                });
              });
            },
            getSanralTaxisByTaxiAssId({
              state, rootState, rootGetters, commit, dispatch, getters,
            }, { taxiassociation_id,skip,len,router }) {
              return new Promise((resolve, reject) => {
        
                const requestheader = {
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.SANRALETAGBYASSIDDROPDOWN_URL,
                };
        
                this._vm.$TAMSFIN_API.getByPaging(requestheader, {status:taxiassociation_id,skip,len}).then((parsedResult) => {
                  if (parsedResult.status) {
                    //commit('LOADED', parsedResult.data);
                    resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                  reject();
                });
              });
            },
            topupaccount({
                  state,
                  rootState,
                  rootGetters,
                  commit,
                  dispatch,
                  getters,
                }, {
                  router,
                  payload,
                }) {
                  return new Promise((resolve, reject) => {
            
                    const requestheader = {
                      router,
                      token: rootGetters['auth/TOKEN'],
                      apiName: config.SANRALETAGPAYMENTSCUSTOM_URL,
                    };
            
                    this._vm.$TAMSFIN_API.add(requestheader,
                      payload).then((parsedResult) => {
                      console.log('Payment made: ', parsedResult);
                      if (parsedResult.status) {
                        console.log('Payment made: ', )
                        commit('LOADPAYMENTRESULTS', parsedResult.data);
                        resolve(parsedResult);
                        // navigateTo(router, parsed_result.data[0]);
                      } else
                        reject(arsedResult.data[0]);
                    }).catch((error) => {
                      // commit('LOGINFAILED', error.data);
                      reject(error);
                    });
                  });
                },
                AddCustomer({
                  state,
                  rootState,
                  rootGetters,
                  commit,
                  dispatch,
                  getters,
                }, {
                  router,
                  payload,
                }) {
                  return new Promise((resolve, reject) => {
            
                    const requestheader = {
                      router,
                      token: rootGetters['auth/TOKEN'],
                      apiName: config.SANRALETAADDCUSTOMERCUSTOM_URL,
                    };
            
                    this._vm.$TAMSFIN_API.add(requestheader,
                      payload).then((parsedResult) => {
                      console.log('Payment made: ', parsedResult);
                      if (parsedResult.status) {
                        console.log('Payment made: ', )
                        commit('LOADPAYMENTRESULTS', parsedResult.data);
                        resolve(parsedResult);
                        // navigateTo(router, parsed_result.data[0]);
                      } else
                        reject(arsedResult.data[0]);
                    }).catch((error) => {
                      // commit('LOGINFAILED', error.data);
                      reject(error);
                    });
                  });
                },
                addMobile({
                  state,
                  rootState,
                  rootGetters,
                  commit,
                  dispatch,
                  getters,
                }, {
                  router,
                  payload,
                }) {
                  return new Promise((resolve, reject) => {
            
                    const requestheader = {
                      router,
                      token: rootGetters['auth/TOKEN'],
                      apiName: config.SANRALETAADDCUSTOMERMOBILENOCUSTOM_URL,
                    };
            
                    this._vm.$TAMSFIN_API.add(requestheader,
                      payload).then((parsedResult) => {
                      console.log('Payment made: ', parsedResult);
                      if (parsedResult.status) {
                        console.log('Payment made: ', )
                        commit('LOADPAYMENTRESULTS', parsedResult.data);
                        resolve(parsedResult);
                        // navigateTo(router, parsed_result.data[0]);
                      } else
                        reject(arsedResult.data[0]);
                    }).catch((error) => {
                      // commit('LOGINFAILED', error.data);
                      reject(error);
                    });
                  });
                },
                AddVehicle({
                  state,
                  rootState,
                  rootGetters,
                  commit,
                  dispatch,
                  getters,
                }, {
                  router,
                  payload,
                }) {
                  return new Promise((resolve, reject) => {
            
                    const requestheader = {
                      router,
                      token: rootGetters['auth/TOKEN'],
                      apiName: config.SANRALETAADDVEHICLECUSTOM_URL,
                    };
            
                    this._vm.$TAMSFIN_API.add(requestheader,
                      payload).then((parsedResult) => {
                      console.log('Payment made: ', parsedResult);
                      if (parsedResult.status) {
                        console.log('Payment made: ', )
                        commit('LOADPAYMENTRESULTS', parsedResult.data);
                        resolve(parsedResult);
                        // navigateTo(router, parsed_result.data[0]);
                      } else
                        reject(arsedResult.data[0]);
                    }).catch((error) => {
                      // commit('LOGINFAILED', error.data);
                      reject(error);
                    });
                  });
                },
            getSanralCustomersByTaxiAssId({
              state, rootState, rootGetters, commit, dispatch, getters,
            }, { id,router }) {
              return new Promise((resolve, reject) => {
        
                const requestheader = {
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.SANRALETAGCUSTOMERSBYASSID_URL,
                };
        
                this._vm.$TAMSFIN_API.getById(requestheader,id).then((parsedResult) => {
                  if (parsedResult.status) {
                    //commit('LOADED', parsedResult.data);
                    resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                  reject();
                });
              });
            },
            getSanralCustomersById({
              state, rootState, rootGetters, commit, dispatch, getters,
            }, { id,router }) {
              return new Promise((resolve, reject) => {
        
                const requestheader = {
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.SANRALETAGCUSTOMERSBYID_URL,
                };
        
                this._vm.$TAMSFIN_API.getById(requestheader,id).then((parsedResult) => {
                  if (parsedResult.status) {
                    //commit('LOADED', parsedResult.data);
                    resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                  reject();
                });
              });
            },
            getSanralCustomersDepositType({
              state, rootState, rootGetters, commit, dispatch, getters,
            }, { router }) {
              return new Promise((resolve, reject) => {
        
                const requestheader = {
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.SANRALETAGBYDEPOSITTYPE_URL,
                };
        
                this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResult) => {
                  if (parsedResult.status) {
                    //commit('LOADED', parsedResult.data);
                    resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                  reject();
                });
              });
            },
            getSanralVehicleTransactionsById({
                  state,
                  rootState,
                  rootGetters,
                  commit,
                  dispatch,
                  getters,
                }, {
                  id,
                  skip,
                  len,
                  router
                }) {
                  
                  return new Promise((resolve, reject) => {
                    const requestheader = {
                      router,
                      token: rootGetters['auth/TOKEN'],
                      apiName: config.SANRALETAGTRANSACTIONSBYID_URL,
                    };
            
                    this._vm.$TAMSFIN_API.getByPaging(requestheader, {
                      status: id,
                      skip: skip,
                      len: len
                    }).then((parsedResultOP) => {
                      if (parsedResultOP.status) {
                        console.log("vehicle transactions by id: ", parsedResultOP.data);
                        // commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
                        resolve(parsedResultOP.data);
                        // navigateTo(router, parsed_result.data[0]);
                      }
                    }).catch((error) => {
                      console.log(error);
                      reject(error);
                      // commit('LOGINFAILED', error.data);
                    });
                  });
                },

    getRoutesByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { taxiassociationid,skip,len,router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPROUTESBYASSID_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {status:taxiassociationid,skip,len}).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ROUTESLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getCardTransactionsByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { taxiassociationid,skip,len,router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTRANSACTIONSBYASSID_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {status:taxiassociationid,skip,len}).then((parsedResult) => {
          if (parsedResult.status) {
            commit('CARDTRANSACTIONDBYASSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    getCommuterCardTransactionsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,skip,len,router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTRANSACTIONSBYID_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {status:id,skip,len}).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERCARDTRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addVIPRoute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTAXIROUTEPRICINGADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    getRoutePricingByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { taxiassociationid,skip,len,router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTAXIROUTEPRICINGBYASSID_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {status:taxiassociationid,skip,len}).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ROUTEPRICINGLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getcommuterCardsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERCARDSBYUSERID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('COMMUTERCARDLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getcommuterRoutesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERROUTES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('COMMUTERROUTESLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getcardInventoryById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDINVENTORYBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('CARDLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVIPRoutePricingById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTAXIROUTEPRICINGBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {

            console.log("route price found: ",parsedResult.data )
            commit('ROUTEPRICELOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getCommutercardById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERCARDSBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('COMMUTERCARDLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    

    getcardInventoryByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDINVENTORYBYASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('CARDSBYASSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCommuter({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERSADD_URL,
        };
        console.log(_dataObj);

        const AddModel = {
          genderid: _dataObj.genderid,
        firstname: _dataObj.firstname,
        surname: _dataObj.surname,
        id_number: _dataObj.id_number,
        mobilenumber: _dataObj.mobilenumber,
        physical_address: _dataObj.physical_address,
        physical_address2: _dataObj.physical_address2,
        suburb: _dataObj.suburb,
        city: _dataObj.city,
        provinceid: _dataObj.provinceid,
        capturephotosessionid: _dataObj.capturephotosessionid,
        addedby: _dataObj.addedby
        }

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('commuter added: ', parsedResult.data);

            commit('COMMUTERADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('commuter error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCommuterCard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('commuter added: ', parsedResult.data);
            // commit('COMMUTERADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('commuter card error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCardInventory({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDINVENTORYADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('card added: ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('card inventory error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCardTaxiRoute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTAXIROUTEADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('card Route added: ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('card Route error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCardTaxiRoutePricing({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {

          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDTAXIROUTEPRICINGADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('card Route pricing added: ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('card Route error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addCardPayment({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {

          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCARDPAYMENT_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('card payment: ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('card payment error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    deleteCommuterCard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, deletedby, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPDELETECARD_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },
    blockCommuterCard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {

          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPBLOCKCARD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('block card : ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('block card errors: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    unblockCommuterCard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {

          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPUNBLOCKCARD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('unblock card : ', parsedResult.data);
            // commit('CARDINVENTORYADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('unblock card errors: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    
    updateCommuter({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, updateModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERSUPDATE_URL,
        };

        this._vm.$TAMS_API.update(requestheader,id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('commuter updated: ', parsedResult.data);
            // commit('COMMUTERADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('commuter update error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateCommuterCard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, updateModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VIPCOMMUTERCARDUPDATE_URL,
        };

        this._vm.$TAMS_API.update(requestheader,id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) { 
            console.log('commuter card updated: ', parsedResult.data);
            // commit('COMMUTERADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('commuter card update error: ', error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    update({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        const updateModel = {
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,
        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', { id, deletedby });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader,
          {
            id,
            restoredby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', { id });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getalltransactions({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ETAGALLTRANS_URL,
      };

      
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDCARDS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ETAGDASHBOARDCARDS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDCARDS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getoperatorfinstats({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ETAGDASHBOARDSTATS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          
          //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

  },

};
