<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageUserInfo'"
      :ActiveMenu="'SystemUsers'"
    />
    <!-- end #sidebar -->
    <!-- begin #content -->
    <div id="content" class="content content-full-width">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="User Profile"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Office"
        breadcrumbitem3="User Profile"
      />
      <!-- end page-header -->

      <hr class="bg-grey-lighter" />
      <!-- begin profile -->
      <div class="profile">
        <div class="profile-header">
          <!-- BEGIN profile-header-cover -->
          <div class="profile-header-cover"></div>
          <!-- END profile-header-cover -->
          <!-- BEGIN profile-header-content -->
          <div class="profile-header-content">
            <!-- BEGIN profile-header-img -->
            <div class="profile-header-img" style="height:140px;">
              <img v-if="PICTURE" :src="`${PICTURE != undefined ? PICTURE : '' } `" alt />
            </div>
            <!-- END profile-header-img -->
            <!-- BEGIN profile-header-info -->
            <div class="profile-header-info">
              <h4
                class="m-t-10 m-b-5"
              >{{UPDATEUSERPROFILE.firstname}} {{UPDATEUSERPROFILE.surname}}</h4>
              <p class="m-b-10">{{USERGROUPDESC}}</p>
              <a href="#" class="btn btn-xs btn-yellow">Edit Profile</a>
            </div>
            <!-- END profile-header-info -->
          </div>
          <!-- END profile-header-content -->
          <!-- BEGIN profile-header-tab -->
          <ul class="profile-header-tab nav nav-tabs width-full">
            <li class="nav-item">
              <a href="#profile-about" class="nav-link active" data-toggle="tab">ABOUT</a>
            </li>
            <li class="nav-item">
              <a href="#profile-photos" class="nav-link" data-toggle="tab">PHOTOS</a>
            </li>
            <li class="nav-item" v-if="ISINTERNALSYSTEMUSER">
              <a href="#profile-roles" class="nav-link" data-toggle="tab">ROLES</a>
            </li>
          </ul>
          <!-- END profile-header-tab -->
        </div>
      </div>
      <!-- end profile -->
      <!-- begin profile-content -->
      <div class="profile-content">
        <!-- begin tab-content -->
        <div class="tab-content p-0">
          <!-- begin #profile-about tab -->
          <div class="tab-pane fade show active" id="profile-about">
            <!-- begin table -->
            <div class="table-responsive">
              <table class="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>
                        {{ context.ASSOCIATION_NAME || 'No Association'}}
                        <small>{{context.ASSOCIATION_ABBR || 'No Association code'}}</small>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="field">
                      <i class="fa fa-tag fa-lg m-r-5"></i> Title
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            data-size="10"
                            id="title"
                            data-live-search="true"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            v-model="UPDATEUSERPROFILE.titleid"
                            disabled
                          >
                            <option value="0" selected>Select a Title</option>
                            <option
                              v-bind:key="item._id"
                              v-for="item in GETALLTITLES"
                              :value="item._id"
                            >{{item._name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-id-card fa-lg m-r-5"></i>Username
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="username"
                            name="username"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            v-model="UPDATEUSERPROFILE.username"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-user fa-lg m-r-5"></i>Firstname
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            v-model="UPDATEUSERPROFILE.firstname"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-user fa-lg m-r-5"></i>Surname
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="surname"
                            name="surname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            v-model="UPDATEUSERPROFILE.surname"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-barcode fa-lg m-r-5"></i> ID Number
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="idNumber"
                            name="idNumber"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            placeholder="N/A"
                            v-model="UPDATEUSERPROFILE.idnumber"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-mobile fa-lg m-r-5"></i>Mobile
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="number"
                            id="mobileNumber"
                            name="surname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            v-model="UPDATEUSERPROFILE.mobilenumber"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-venus-mars fa-lg m-r-5"></i>Gender
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            id="gender"
                            data-size="10"
                            data-live-search="true"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            v-model="UPDATEUSERPROFILE.genderid"
                            disabled
                          >
                            <option value="0" selected>Select a Gender</option>
                            <option
                              v-bind:key="item._id"
                              v-for="item in GETALLGENDERS"
                              :value="item._id"
                            >{{item._name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-mobile fa-lg m-r-5"></i>Dialer Agent UserId
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="number"
                            id="dialeragentuserid"
                            name="surname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            v-model="UPDATEUSERPROFILE.dialeragentuserid"
                            disabled
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-address-card fa-lg m-r-5"></i>User Group
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <a
                            href="javascript:;"
                            id="userRoleGroup"
                            data-type="select"
                            data-pk="1"
                            data-value
                            data-title="Select User group"
                          ></a>
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr class="highlight" v-if="!passwordChanger">
                    <td class="field">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div
                        
                        class="alert alert-white fade show"
                      >
                        <button
                          v-if="!enabled"
                          @click="enableFields(context)"
                          type="button"
                          class="btn btn-yellow width-150 m-5"
                        >Update Profile</button>
                        <button
                          v-if="enabled"
                          @click="systemuserUpdate(context,UPDATEUSERPROFILE)"
                          type="button"
                          class="btn btn-success width-150 m-5"
                        >Update</button>
                        <button
                          type="submit"
                          class="btn btn-primary width-150 m-r-2"
                          href="#modal-dialog"
                          data-toggle="modal"
                        >Change Password</button>
                        <button
                          @click="RunTestBAS(context)"
                          type="button"
                          class="btn btn-success width-150 m-5"
                        >BAS Login Test</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table -->
            <!-- #modal-dialog -->
            <div class="modal fade" id="modal-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 class="modal-title">Change password</h4>
                  </div>
                  <div class="modal-body">
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label class="col-md-0 col-form-label text-md-right">
                        New Password:
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <input
                          v-model="UPDATEUSERPASSWORD.newPassword"
                          data-parsley-required="true"
                          data-parsley-length="[0,4]"
                          data-toggle="password"
                          data-placement="before"
                          class="form-control"
                          type="password"
                          placeholder="password"
                          maxlength="4" required
                        />
                      </div>
                    </div>
                    <!-- end form-group -->
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      @click="systemuserUpdatePassword(context,UPDATEUSERPASSWORD)"
                      class="btn btn-success width-150 m-r-5"
                      data-dismiss="modal"
                    >Submit</button>
                    <button
                      type="button"
                      @click="systemuserUpdateGeneratedPassword(context, UPDATEUSERGENERATEDPASSWORD)"
                      data-dismiss="modal"
                      class="btn btn-primary width-150 m-r-5"
                    >Generated Password</button>
                    <a
                      href="javascript:;"
                      class="btn btn-white width-150 m-r-5"
                      data-dismiss="modal"
                    >Close</a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end #profile-about tab -->
          <!-- begin #profile-photos tab -->
          <div class="tab-pane fade in" id="profile-photos">
            <h4
              v-if="USERPHOTOS > 0
            "
              class="m-t-0 m-b-20"
            >Photos ({{USERPHOTOS.length}})</h4>
            <h4
              v-if="!USERPHOTOS > 0
            "
              class="m-t-0 m-b-20"
            >No Photos Uploaded.</h4>
            <!-- start superbox -->
            <div class="superbox" data-offset="50">
              <div
                class="superbox-list"
                :key="'UserImages '+ item"
                v-for="item in USERPHOTOS"
              >
                <a href="javascript:;" class="superbox-img" rel="superbox[superbox-img]">
                  <img :src="`${item.data}`" class="width-full height-sm" />
                  <span :style="{ 'background-image': 'url(' + item.data + ')' }"></span>
                </a>

                <div class="text-center">
                  <button type="button" class="btn btn-yellow btn-sm m-5">Edit</button>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm m-5"
                    @click="Delete(context, item.fileid)"
                  >Delete</button>
                </div>
              </div>
            </div>
            <!-- end superbox -->
          </div>
          <!-- end #profile-photos tab -->
          <!-- begin #profile-roles tab -->
          <div class="tab-pane fade in" id="profile-roles">
            <h4
              class="m-t-0 m-b-20"
              v-if="GETROLESBYASSOCIATION > 0"
            >Roles {{GETROLESBYASSOCIATION.length}}</h4>
            <h4 class="m-t-0 m-b-20">Roles</h4>
            <!-- begin table-responsive -->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th nowrap>ROLE</th>
                    <th nowrap>DESCRIPTION</th>
                    <th nowrap>ROLE STATUS</th>
                    <th nowrap>ROLE CODE</th>
                    <th v-if="isSystemAdmin" nowrap>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="{'success': roles._roleusergroupid != null}"
                    v-for="roles in GETROLESBYASSOCIATION"
                    :key="roles.role_id"
                  >
                    <td>{{roles._role_name}}</td>
                    <td>{{roles._role_description}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': roles._is_active, 'label-secondary':!roles._is_active  }"
                      >{{roles._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{roles._code}}</td>
                    <td v-if="isSystemAdmin">
                      <button
                        v-if="roles._roleusergroupid == null"
                        type="button"
                        class="btn btn-sm btn-yellow width-80"
                        @click="() => {addSystemuserUserGroup(context, roles,roles.role_id)}"
                      >Add</button>
                      <button
                        v-if="roles._roleusergroupid != null"
                        type="button"
                        class="btn btn-sm btn-success btnadded width-80"
                        @click="() => {removeSystemuserUserGroup(context,roles,roles._roleusergroupid)}"
                      >Added</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
          <!-- end #profile-roles tab -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end profile-content -->
    </div>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("systemusers");
// value.context.$emit("init");

export default {
  name: "UserProfile",
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },
  data: function() {
    return {
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      ID: this.$route.params.id,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      SYSTEMUSERPROFILE: this.$store.getters["systemusers/GETUSERPROFILE"][0],
      skip: 0,
      len: 10000,
      GETROLESBYASSOCIATION: [],
      USERPHOTOS: [],
      ASSOCIATION_NAME: "",
      ASSOCIATION_ABBR: "",
      USERGROUPDESC: "",
      USERGROUPNAME: "",
      DEFAULTPICTURE: "/assets/img/user/user-13.jpg",
      PICTURE: undefined,
      ADDONROLEID: [],
      passwordChanger: false,
      enabled: false,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      TOKEN: this.$store.getters["auth/TOKEN"],
      UPDATEUSERPROFILE: {
        systemuserid: this.$route.params.id,
        firstname: "",
        surname: "",
        idnumber: "",
        mobilenumber: "",
        username: "",
        titleid: "",
        genderid: "",
        dialeragentuserid: "",
        usergroupsid: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      UPDATEUSERPASSWORD: {
        id: "",
        firstname: "",
        surname: "",
        mobilenumber: "",
        newPassword: null,
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      UPDATEUSERGENERATEDPASSWORD: {
        id: "",
        firstname: "",
        surname: "",
        mobilenumber: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      ADDSYSTEMUSERGROUPROLE: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        role_id: null,
        usergroups_id: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      DELETESYSTEMGROUPROLE: {
        role_usergroups_id: null,
        deletedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
    };
  },
  beforeMount: function() {
    
  },
  computed: {
    ...mapGetters([
      "GETALL",
      "GETUSERPROFILE",
      "GETUSERGROUPSBYASSOCIATION"
    ]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      "getById",
      "getRolesByAssociation",
      "getUserGroupsByAssociation",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "update",
      "updateUserPassword",
      "updateUserGeneratedPassword",
      "addSystemuserUserGroupRole",
      "removeSystemuserUserGroupRole",
      "clearAll",
      "updateUserGroup",
      "updateSystemUser"
    ]),
    getSystemuserById: context => {
      context
        .getById({
          id: context.ID,
          router: context.$router
        })
        .then(_rb => {

          console.log("This is the system userProfile", _rb[0]);

          //user profile binding
          if (_rb[0]) {

            context.USERPHOTOS = _rb[0]._images;

            console.log('user results..',_rb[0]);

            context.ASSOCIATION_NAME = _rb[0]._taxi_associations_name;

            context.ASSOCIATION_ABBR=  _rb[0]._taxi_associations_abbr,

            context.USERGROUPDESC = _rb[0]._usergroups_desc;
            context.USERGROUPNAME = _rb[0]._usergroups_name;

            //user update binding.
            
            context.UPDATEUSERPROFILE.firstname = _rb[0]._firstname;
            context.UPDATEUSERPROFILE.surname = _rb[0]._surname;
            context.UPDATEUSERPROFILE.idnumber = _rb[0]._id_number;
            context.UPDATEUSERPROFILE.mobilenumber = _rb[0]._mobile_number;
            context.UPDATEUSERPROFILE.username = _rb[0]._username;
            context.UPDATEUSERPROFILE.titleid = _rb[0]._title_id;
            context.UPDATEUSERPROFILE.genderid = _rb[0]._gender_id;
            context.UPDATEUSERPROFILE.systemuserid = _rb[0]._system_users_id;
            context.UPDATEUSERPROFILE.usergroupsid = _rb[0]._usergroups_id;
            context.UPDATEUSERPROFILE.dialeragentuserid = _rb[0]._dialeragentuserid;

            //password update binding
            
            context.UPDATEUSERPASSWORD.firstname = _rb[0]._firstname;
            context.UPDATEUSERPASSWORD.surname = _rb[0]._surname;
            context.UPDATEUSERPASSWORD.mobilenumber = _rb[0]._mobile_number;
            context.UPDATEUSERPASSWORD.id = _rb[0]._system_users_id;

            //generated password binding
            
            context.UPDATEUSERGENERATEDPASSWORD.firstname = _rb[0]._firstname;
            context.UPDATEUSERGENERATEDPASSWORD.surname = _rb[0]._surname;
            context.UPDATEUSERGENERATEDPASSWORD.mobilenumber =
              _rb[0]._mobile_number;
              context.UPDATEUSERGENERATEDPASSWORD.id = _rb[0]._system_users_id;

            // adding user role binding

            context.ADDSYSTEMUSERGROUPROLE.taxi_association_id =
              _rb[0]._fk_taxi_associations_id;
            context.ADDSYSTEMUSERGROUPROLE.usergroups_id =
              _rb[0]._usergroups_id;

              let usergroupObject = {
                group_id: _rb[0]._usergroups_id,
                group_name: _rb[0]._usergroups_name
              };



              context.getUsergroupsByAss(context,usergroupObject);

            
          }
        });
    },

    getRolesByAss: context => {
      context
        .getRolesByAssociation({
          associationid: context.taxiassociationid,
          userid: context.ID,
          router: context.$router
        })
        .then(_rb => {
          console.log(_rb);
          context.GETROLESBYASSOCIATION = _rb;
        });
    },

    getUsergroupsByAss: (context,usergroupObject) => {
      context
        .getUserGroupsByAssociation({
          id: context.taxiassociationid,
          router: context.$router
        })
        .then(_rb => {
          console.log("usergroups found : ", _rb);

          const usergroups = _rb.map(element => {
            return {
              value: element._id,
              text: element._name
            };
          });
          console.log("usergroups: " + JSON.stringify(usergroups));

          let userRoleGroup = "";
          userRoleGroup = usergroupObject.group_name;
          initiateUserGroups(usergroups,userRoleGroup);
        })
        .catch(error => {
          console.log("usergroup errors: ", error);
        });
    },

    systemuserUpdate: (context, _addModel) => {
      context.$Notify.YesNo(false, { context, _addModel }).then(value => {
        
        if (value) {
          value.router = value.context.$router;
          value.context
            .updateSystemUser({
              _addModel,
              router: value.router
            })
            .then(_rb => {
              console.log("added", _rb);
              context.$Notify.Success("Updated Successfully!", "");
              context.disableFields(value.context);
            })
            .catch(error => {
              context.$wait.end("add");

              context.$Notify.Error(error.data.message, error);
            })
            .finally(() => {});
        } else {
          context.disableFields(context);
        }
      });
    },
    RunTestBAS: (context) => {
      
      fetch("https://mainapi.bookaseat.africa/api/v1/authentication/LoginAgent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          mobilenumber: context.UPDATEUSERPROFILE.mobilenumber,
          idnumber: context.UPDATEUSERPROFILE.idnumber,
          tamssystemUserid: context.UPDATEUSERPASSWORD.id,
          userlocation: null,
          platformtype_code: "AND",
          pushtoken: context.TOKEN
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.Status) {
            //alert("Success:", data);
            //console.log("Success:", data);
            context.$Notify.Success("Login Successfully" , "");
          } else {
            context.$Notify.Error(data.Message, "");
          }
          console.log(data);
        })
        .catch(error => {
          console.error("Error:", error);
          context.$Notify.Error("Test BAS Failed!", "");
        });
    },
    systemuserUpdatePassword: (context, _updateModel) => {
      context.UPDATEUSERPASSWORD.mobilenumber =
        context.UPDATEUSERPROFILE.mobilenumber;
      context.$Notify.YesNo(false, { context, _updateModel }).then(value => {
        if (value) {
          value.router = value.context.$router;
          value.context
            .updateUserPassword({
              _updateModel,
              router: value.router
            })
            .then(_rb => {
              console.log("added", _rb);
              value.context.$Notify.Success(
                "Password Updated Successfully!",
                ""
              );
              context.UPDATEUSERPASSWORD.newPassword = "";
              context.UPDATEUSERPASSWORD.mobilenumber = null;
              // value.context.disableFields(value.context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              $("#modal-dialog").modal("hide");
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        } else {
          // context.disableFields(context);
        }
      });
    },

    systemuserUpdateGeneratedPassword: (context, _updateModel) => {
      context.UPDATEUSERGENERATEDPASSWORD.mobilenumber =
        context.UPDATEUSERPROFILE.mobilenumber;

      context.$Notify.YesNo(false, { context, _updateModel }).then(value => {
        if (value) {
          value.router = value.context.$router;
          value.context
            .updateUserGeneratedPassword({
              _updateModel,
              router: value.router
            })
            .then(_rb => {
              console.log("added", _rb);
              value.context.$Notify.Success(
                "Password Generated Successfully!",
                ""
              );
              // value.context.disableFields(value.context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              $("#modal-dialog").modal("hide");
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        } else {
          // context.disableFields(context);
        }
      });
    },

    addSystemuserUserGroup: (context, item,id) => {
      // let roleID = context.ADDONROLEID.map((results)=>{
      //   return results.
      // })
      console.log(id);
      context.ADDSYSTEMUSERGROUPROLE.role_id = id;
      context.ADDSYSTEMUSERGROUPROLE.usergroups_id = context.ID;
      context.$Notify.YesNo(false, { context, _addModel: context.ADDSYSTEMUSERGROUPROLE,item }).then(value => {
        if (value) {
          value.router = value.context.$router;
          value.context
            .addSystemuserUserGroupRole({
              _addModel: value._addModel,
              router: value.router
            })
            .then(_rb => {
              console.log("added", _rb);

              if(_rb.status){
                value.item._roleusergroupid = _rb.data[0].fn_role_systemuser_add;
                value.context.$Notify.Success("Role Assigned Successfully!", "");
              } else {
                value.context.$Notify.Error("Failed To Add A Role!", "");
              }
             
              // value.context.disableFields(value.context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              $("#modal-dialog").modal("hide");
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        } else {
          // context.disableFields(context);
        }
      });
    },

    removeSystemuserUserGroup: (context, item, id) => {
      context.DELETESYSTEMGROUPROLE.role_usergroups_id = id;
      context.$Notify.YesNo(false, { context, addModel: context.DELETESYSTEMGROUPROLE,item }).then(value => {
        
        if (value) {
          value.router = value.context.$router;
          value.context
            .removeSystemuserUserGroupRole({
              _addModel:value.addModel,
              router: value.router
            })
            .then(_rb => {
              if(_rb.status){
                value.item._roleusergroupid = null;
                value.context.$Notify.Success("Role Removed Successfully!", "");
              } else {
                value.context.$Notify.Error("Failed To Delete A Role!", "");
              }
              
              // value.context.disableFields(value.context);
            })
            .catch(error => {
              
              context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        } else {
          // context.disableFields(context);
        }
      });
    },

    enableFields: context => {
      document.getElementById("title").disabled = false;
      document.getElementById("username").disabled = false;
      document.getElementById("firstname").disabled = false;
      document.getElementById("surname").disabled = false;
      document.getElementById("idNumber").disabled = false;
      document.getElementById("mobileNumber").disabled = false;
      document.getElementById("gender").disabled = false;
      document.getElementById("dialeragentuserid").disabled = false;
      context.enabled = true;
    },

    disableFields: context => {
      document.getElementById("title").disabled = true;
      document.getElementById("username").disabled = true;
      document.getElementById("firstname").disabled = true;
      document.getElementById("surname").disabled = true;
      document.getElementById("idNumber").disabled = true;
      document.getElementById("mobileNumber").disabled = true;
      document.getElementById("gender").disabled = true;
      document.getElementById("dialeragentuserid").disabled = true;
      context.enabled = false;
    },

    Delete: (context, id) => {
      context.$store.dispatch("capturephotos/deletephoto", {
        id,
        deletedby: context.USERID,
        router: context.$router
      });
      $(".btnsave")
        .text("Save")
        .removeClass("disabled")
        .attr("disabled", false);
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLTITLES = data.LOADEDTITLES;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
  },
  mounted: function() {

    
    this.loadLookUps(this.context);
    this.getSystemuserById(this.context);
    this.getRolesByAss(this.context);

    
    App.init();

    $("#userRoleGroup").data("context", this.context);

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    this.SYSTEMUSERPROFILE = this.$store.getters[
      "systemusers/GETUSERPROFILE"
    ][0];
    const contexttemp = this;
    this.$store
      .dispatch("systemusers/getProfilePicture", {
        id: this.ID
      })
      .then(result => {
        contexttemp.PICTURE = result.data[0].fn_system_users_get_profilepicture
          ? result.data[0].fn_system_users_get_profilepicture.data
          : "/assets/img/user/user-13.jpg";
      })
      .catch(err => {
        console.log(err);
      });


    // this.ADDONROLEID = [];

    $(".superbox").SuperBox({
      background: "#242a30",
      border: "rgba(0,0,0,0.1)",
      xColor: "#a8acb1",
      xShadow: "embed",
      boxWidth: "600", // Default width of the box
      boxHeight: "400",
      loadTxt: "Loading...", // Loading text
      closeTxt: "Close", // "Close" button text
      prevTxt: "Previous", // "Previous" button text
      nextTxt: "Next" // "Next" button text,
    });

    
  },
  // beforeDestroy: function() {
  //   this.clearUser(this.context);
  // }
};

var initiateUserGroups = function(usergroups, userRoleGroup) {
  const context = $("#userRoleGroup").data("context");
  $("#userRoleGroup").editable({
    prepend: userRoleGroup,
    source: usergroups,
    url: null,
    mode: "inline",
    success: function(response, newValue) {
      
      console.log(this.prepend);
      const context = $("#userRoleGroup").data("context");
      context.$Notify.YesNo(false, { context, newValue }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context
            .updateUserGroup({
              _updateModel: {
                userID: context.ID,
                usergroupsid: newValue,
                updatedby: context.USERID
              },
              router: value.router,
            })
            .then(_rb => {
              console.log(_rb);
              this.prepend = _rb[0]._usergroups_name;
              console.log(this.prepend);
              $("#AddModal").modal("hide");
              $("#userRoleGroup").editable('setValue', this.prepend,true);
              value.context.$wait.end("add");
              value.context.$Notify.Success("User Group Changed Successfully", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    }
  });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>