<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FuelManager'"
      :ActiveMenu="'FuelOperations'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          :pageheaderTitle="'Fuel Operations '"
          pageheaderdescription=""
          breadcrumbitem1="Home"
          breadcrumbitem2="Fuel Manager"
          breadcrumbitem3="Operations"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <div id="advance-daterange" class="btn btn-default btn-block text-left m-b-10 width-400">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                         <div v-show="$wait.is('loading')" class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
                      </div>
                      

           <!-- begin row -->
           <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">REBATE - UNLEADED 93</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.unleaded93rebates | currencywithSymbol}}</div>

                <div class="stats-desc">{{GETDASHBOARDCARDS.unleaded93litres}} Litres And {{GETDASHBOARDCARDS.unleaded93price | currencywithSymbol}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-green">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">REBATE - UNLEADED 95</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.unleaded95rebates | currencywithSymbol}}</div>

                <div class="stats-desc">{{GETDASHBOARDCARDS.unleaded95litres}} Litres And {{GETDASHBOARDCARDS.unleaded95price | currencywithSymbol}}</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">REBATE - 50 PPM DIESEL</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.diesel50rebates | currencywithSymbol}}</div>

                <div class="stats-desc">{{GETDASHBOARDCARDS.diesel50litres}} Litres And {{GETDASHBOARDCARDS.diesel50price | currencywithSymbol}}</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">REBATE - 500 PPM DIESEL</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.diesel500rebates | currencywithSymbol}}</div>

                <div class="stats-desc">{{GETDASHBOARDCARDS.diesel500litres}} Litres And {{GETDASHBOARDCARDS.diesel500price | currencywithSymbol }}</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
         
           
        </div>

        <!-- end row -->

        

          <!-- begin row -->
          <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">TRIPS AND FUEL TRANSACTIONS </h4>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <!-- <th>DATE</th> -->
                  <th>OPERATOR</th>
                  <th>TAXI</th>
                  <th>ROUTE</th>
                  <th>TRIPS</th>
                  <th>PASSANGERS</th>
                  <th>FARES</th>
                  <th>FUEL TRANS</th>
                  <th>LITRES</th>
                  <th>FUEL COST</th>
                  <th>REBATE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETALLTRANSACTIONS"
                  v-bind:key="item.id"
                  :class="{'success': item.successrate >= 60,
                  'warning': item.successrate >= 50 && item.successrate < 60,
                  'danger': item.successrate < 50
                  }"
                >
                <td>{{++index}}</td>
                <!-- <td>{{item.transdate}}</td> -->
                  <td>{{item.operators}}</td>
                  <td>{{item.vehicle}}</td>
                  <td>{{item.route}}</td>
                  <td>{{item.numberoftrips}}</td>
                  <td>{{item.numberofpassagers}}</td>
                  <td>{{item.totalfares | newcurrencywithSymbol}}</td>
                  <td>{{item.totalfueltrans}}</td>
                  <td>{{item.totalnumoflitres}}</td>
                  <td>{{item.totalfuelprice | newcurrencywithSymbol}}</td>
                  <td>{{item.totalrebatefee | newcurrencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
        </div>
        <!-- end row -->

           <!-- begin row -->
           <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">ALL VEHICLES </h4>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <th>OPERATOR</th>
                  <th>TAXI</th>
                  <th>FUEL TRANS</th>
                  <th>LITRES</th>
                  <th>FUEL COST</th>
                  <th>REBATE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETALLVEHICLESTRANSACTIONS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                <td>{{++index}}</td>
                  <td>{{item.operators}}</td>
                  <td>{{item.vehicle}}</td>
                  <td>{{item.totalfueltrans}}</td>
                  <td>{{item.totalnumoflitres}}</td>
                  <td>{{item.totalfuelprice | newcurrencywithSymbol}}</td>
                  <td>{{item.totalrebatefee | newcurrencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
        </div>
        <!-- end row -->

      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fuelmanager_dashboard"
);

export default {
  name: "realtimerankmanager_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      rankid: this.$route.params.id,
      rankname: this.$route.params.desc,
      GETDASHBOARDCARDS: {},
      GETALLTRANSACTIONS:[],
      GETALLVEHICLESTRANSACTIONS:[],
      GETROUTESTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETQEUEUEDDETAILS: {},
      GETBOARDQEUEUEDDETAILS: {},
      GETTRIPSDETAILS: {},
      GETMOSTTRIPSTOP5DETAILS: {},
      GETMOSTLEASTTOP5DETAILS: {},
      startDate:null,
      endDate:null,
      GETALLROUTES: {},
      ROUTEID: {},
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      paymentsloaded: false,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getfuelmanageropsdashboardcards", "getoperationstransactions"]),
    init: function() {},
    Delete: (context,item, id, deletedby) => {
      
      context.$Notify.YesNo(false, { context, item,id, deletedby }).then((value) => {
        if (value) {
          // value.context.$wait.start("delete-" + value.id);

          // var router = value.context.$router;

          //  var payload = {
          //     tripid: value.id,
          //     userid: value.deletedby,
          //   };

          // value.context.markAsDeleted({ router, payload })
          //   .then(() => {
          //     //value.context.GETVEHICLEBYASSID = value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id !== value.id);
          //     value.context.$wait.end("delete-" + value.id);
          
          //     value.context.$Notify.Success("Successfully Deleted!", "");
               value.item.date_deleted = value.id;
          //   })
          //   .catch((error) => {
          //     value.context.$wait.end("delete-" + value.id);
          //     value.context.$Notify.Error(error.data[0].message, "");
          //   })
          //   .finally(() => {});
        }
      });
    },
    onChange(context) {

      const router = context.$router;

      var payload = {
          taxiassociationsId: context.USERPROFILE.taxi_associations_id,
          startDate: context.startDate,
          endDate:context.endDate,
        };

    context.getfuelmanageropsdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      context.getoperationstransactions({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETALLTRANSACTIONS = parsedResultOP.alltripsdata;
        context.GETALLVEHICLESTRANSACTIONS = parsedResultOP.allvehicles;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
           
           var options = {
                   dom: "lBfrtip",
                   buttons: [
                     { extend: "copy", className: "btn-sm" },
                     { extend: "csv", className: "btn-sm" },
                     { extend: "excel", className: "btn-sm" },
                     { extend: "pdf", className: "btn-sm" },
                     { extend: "print", className: "btn-sm" }
                   ],
                   responsive: false,
                   autoFill: false,
                   colReorder: true,
                   keys: false,
                   rowReorder: false,
                   select: false
                 };

                 if ($(window).width() <= 767) {
                   options.rowReorder = false;
                   options.colReorder = false;
                   options.autoFill = false;
                 }

                
                 if(!context.paymentsloaded){
                   $(".table-invoice").DataTable(options);
                 }

                 context.paymentsloaded = true;

         });

    }
  },
  mounted: function() {
    $(document).data("context", this);

    App.init();

    $("#advance-daterange span").html(
      moment().subtract(1, "months")
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(1, "months"),
        endDate: moment(),
        minDate: "01/01/2015",
        maxDate: "12/31/2050",
        dateLimit: { days: 720 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        

        const context = $(document).data("context");
        context.startDate = start.format("YYYY-MM-DD");
        context.endDate = end.format("YYYY-MM-DD");

        // $("#advance-daterange span").html(
        //   start.format("YYYY-MM-DD") + " - " + end.format("YYYY-MM-DD")
        // );

        $("#advance-daterange span").html(
          start.format("MMMM D, YYYY") +
            " - " +
            end.format("MMMM D, YYYY")
        );

        context.onChange(context);
        
      }
    );

    this.startDate = $("#advance-daterange").data('daterangepicker').startDate.format("YYYY-MM-DD");
    this.endDate = $("#advance-daterange").data('daterangepicker').endDate.format("YYYY-MM-DD");

    this.onChange(this);
   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>