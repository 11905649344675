/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    city: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.city,
  },
  CITYBYPROVINCEID: state => (id) => {
    try {
      return state.provinces.filter(t => t._city_id == id);
    } catch (error) {

    }
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.city = _data;
      }
    },
    ADDED: (state, _data) => {
      state.city.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.city.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.city.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.city.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.city.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.city.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.city = state.city.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */


    /* Auth.js Mutations */

    addMerchantAccount({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ADDMERCHANTACCOUNT_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getMerchantsByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETMERCHANTSBYASSID_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getMerchantByUserId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETMERCHANTBYUSERID_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getTransactionTypes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETTRANSACTIONTYPES_URL,
      };

      this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    AddTransaction({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ADDMERCHANTTRANSACTION_URL,
      };

      this._vm.$TAMSFIN_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    AddTransactionCashTransfer({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ADDMERCHANTCASHTRANSFERTRANSACTION_URL,
      };

      this._vm.$TAMSFIN_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    reverseCashBackWeb({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.REVERSEMERCHANTCASHBACKTRANSACTION_URL,
      };

      this._vm.$TAMSFIN_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    getMerchantTransactions({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETMERCHANTTRANSACTIONS_URL,
      };
  
      this._vm.$TAMSFIN_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },
    
    update({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
          updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CITY_URL,
        };

        const updateModel = {
          province_id: item._province_id,
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,

        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    startCashupSession({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociationid, 
      agent_id,
      addedby,
      geolocation

    }) {
      return new Promise((resolve, reject) => {
       

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTSTARTCASHUP_URL,
        };

        const AddModel = {
          taxiassociationid: taxiassociationid,
          agent_id: agent_id,
          addedby: addedby,
          geolocation: geolocation
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', par  sedResult.data);
            console.log("Cash up Started: ", parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("Cash up statr errors: ", error);
          reject(error);
        });
      });
    },
    blockedMerchantAccount({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociationid, 
      agent_id,
      addedby,
      geolocation

    }) {
      return new Promise((resolve, reject) => {
       

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTBLOCK_URL,
        };

        const AddModel = {
          taxiassociationid: taxiassociationid,
          agent_id: agent_id,
          addedby: addedby,
          geolocation: geolocation
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', par  sedResult.data);
            console.log("Cash up Started: ", parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("Cash up statr errors: ", error);
          reject(error);
        });
      });
    },
    executeVerify({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      AddModel
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTVERIFYCASHUP_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('CASHUPVERIFYADDED', item);
            // 
            // commit('MARKASDELETEVERIFIED', item.collection_session_id);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getPendingApproval({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTCASHUPPENDINGAPPROVAL_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {

          if (parsedResultOP.status) {
            // commit('PENDINGLOADED', parsedResultOP.data);
            console.log("pending approval data found: ", parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("pending approval data errors: ", error);
          reject(error);

        });
      });
    },
    getApprovedCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTAPPROVEDCASHUP_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log('approved cashup: ', parsedResultOP);
          if (parsedResultOP.status) {
            // commit('APPROVEDLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },
    getPendingCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      skip,
      len,
      router
    }) {
  
      return new Promise((resolve, reject) => {
  
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTPENDINGCASHUP_URL,
        };
  
        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: taxiassociationid,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          if (parsedResultOP.status) {
            // commit('PENDINGLOADED', parsedResultOP.data);
            console.log('pending cashup results: ', parsedResultOP.data);
            resolve(parsedResultOP.data);
  
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('pending cashup errors: ', error);
          reject(error);
        });
      });
    },
    getprepaidservicebldbalance({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      AddModel
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTBLDBALANCEURL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', parsedResult.data);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    ApproveVerifiedCashUp({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      AddModel
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTAPPROVECASHUP_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    endCashupSession({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociationid, 
      cashup_session_id,
      endedby,
      geolocation
    }) {
      return new Promise((resolve, reject) => {
    

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTENDCASHUP_URL,
        };

        const AddModel = {
          taxiassociationid: taxiassociationid,
          cashup_id: cashup_session_id,
          geolocation: geolocation,
          endedby: endedby
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', item);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    UnblockedMerchantAccount({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociationid, 
      agent_id,
      addedby,
      geolocation

    }) {
      return new Promise((resolve, reject) => {
       

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.MERCHANTUNBLOCK_URL,
        };

        const AddModel = {
          taxiassociationid: taxiassociationid,
          agent_id: agent_id,
          addedby: addedby,
          geolocation: geolocation
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', par  sedResult.data);
            console.log("Cash up Started: ", parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("Cash up statr errors: ", error);
          reject(error);
        });
      });
    },
    // restoreDeleted({
    //   state, rootState, rootGetters, commit, dispatch, getters,
    // }, { _dataObj }) {
    //   return new Promise((resolve, reject) => {
    //     const {
    //       restoredby, id, router,
    //     } = _dataObj;

    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.CITY_URL,
    //     };

    //     this._vm.$TAMS_API.restoreDeleted(requestheader,
    //       {
    //         id,
    //         restoredby,
    //       }).then((parsedResult) => {
    //       if (parsedResult.status) {
    //         commit('RESTOREDELETED', { id });
    //         resolve(id);
    //       // navigateTo(router, parsed_result.data[0]);
    //       }
    //     }).catch((error) => {
    //     // commit('LOGINFAILED', error.data);
    //       reject(error);
    //     });
    //   });
    // },

  },

};

