<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'Bookings'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="`Bookings Slots`" 
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="Bookings"
      />
      <!-- end page-header -->

      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">UPCOMING BOOKINGS SLOTS</div>
              <div class="stats-number">{{GETDASHBOARDCARDS.totalcomingbookings}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">WAITING LIST SLOTS</div>
              <div class="stats-number">{{GETDASHBOARDCARDS.totalwaitinglistbookings }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">UNASSIGNED BOOKINGS SLOTS</div>

              <div
                class="stats-number"
              >{{GETDASHBOARDCARDS.totalassignedbookings}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
         <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">PASSANGERS</div>

              <div
                class="stats-number"
              >{{GETDASHBOARDCARDS.totalseatsbookings }}/{{GETDASHBOARDCARDS.totalavailableseatsbookings }}
            
            </div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
      </div>

            <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL UPCOMING BOOKINGS</div>
              <div class="stats-number">{{GETDASHBOARDCARDS.totalupcomingbooking}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL WAITING LIST</div>
              <div class="stats-number">{{GETDASHBOARDCARDS.totalwaitingbooking}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL UNALLOCATED BOOKINGS</div>

              <div
                class="stats-number"
              >{{GETDASHBOARDCARDS.totalcancelledbooking}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
         <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">CANCELLED BOOKINGS</div>

              <div
                class="stats-number"
              >{{GETDASHBOARDCARDS.totalcancelledbooking }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
      </div>

      <!-- begin #Main Body Template -->

     <!-- begin row -->
        <div class="row">

           
            
          <!-- begin col-8 -->
          <div class="col-xl-12">

               <!-- begin panel -->
               <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Upcoming Bookings(30 Days)</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Upcoming Booking
                  <div id="advance-daterange" class="ml-2 mr-2 pr-2 btn btn-light btn-xs">
                    <i class="fa fa-caret-down pull-right m-t-2"></i>
                    <span></span>
                    <div v-show="ISLOADING" class="spinner-border spinner-border-lg text-info"  role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  </div>
                  
                 
                   <select
                    id="selallranks"
                    class="ml-2 mr-2 pr-2 btn btn-light btn-xs"
                    @change="onChange($event)"
                  >
                   <option value="0" selected>Select Rank</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETALLRANKS"
                                  :value="item.id"
                                >{{item.name}}</option>
                  </select>
                   <select
                    id="selallroutes"
                    class="ml-2 mr-2 pr-2 btn btn-light btn-xs"
                    @change="onChangeRoute($event)"
                  >
                   <option value="0" selected>Select Route</option>
                                <option
                                  v-bind:key="item.RankRouteId"
                                  v-for="item in GETALLROUTES"
                                  :value="item.RankRouteId"
                                >{{item.Name}}</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body">
               <table class="table" id="bookingsslots">
                <thead>
                  <tr>
                    <th></th>
                    <th>DATE</th>
                    <th>SEATS</th>
                    <th>SLOTS</th>
                    <th>WAITLIST</th>
                    <th>DESTINATION</th>
                    <th>CHECKINS</th>
                    <th>VEHICLE</th>
                    <th>OPERATOR ACK</th>
                    <th>DRIVER ACK</th>
                    <th>ASSIGN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in BOOKINGS" :key="item.id" :value="item"
                  :class="index & 1 ? 'odd gradeA' : 'even'">
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.booking_date}} {{item.booking_time}}</td>
                    <td>{{item.booked_seats}}/{{item.available_seats+item.booked_seats}}</td>
                    <td>{{item.slotnumber}}/{{item.max_slotnumber}}</td>
                    <td class="valign-middle">{{item.waitinglist}}</td>
                    <td>
                      <router-link
                      :to="{ name: 'bookingsbysession', params: {id: item.id,desc: item.from_route_name + ' @ ' + item.booking_date + ' ' + item.booking_time } }"
                    > {{item.from_route_name}}
                  </router-link>
                    </td>
                    <td class="valign-middle">{{item.totalcheckins}}</td>
                    <td>{{item.regnum}}</td>
                    <td>{{item.driver_accepted}}</td>
                    <td>{{item.operator_accepted}}</td>
                    <!-- <td
                    class="text-left text-green-darker"
                    >{{item.totalfare | newcurrencywithSymbol}}</td> -->
                    <td class="with-btn valign-middle" nowrap>
                      
                      <select
                      id="selallvehicles"
                    class="ml-2 mr-2 pr-2 form-control"
                    @change="onChangeVehicle(context,$event,item)"
                  >
                        <option
                                  key="0"
                                  value="0"
                                >Select Taxi</option>
                                <option
                                  v-bind:key="item_v.id"
                                  v-for="item_v in GETALLVEHICLES"
                                  :value="item_v.vin_number"
                                >{{item_v.licence_number}}</option>
                  </select>
                       <!-- <button
                        type="button"
                        class="btn btn-sm btn-warning width-80"
                        :disabled="SCANSUBMIT"
                        @click="SubmitAssign(context,item)"
                      >Assign</button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->

      <br />

      

      <!-- #modal-dialog -->
      <div class="modal fade" id="changeBilling">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title">Assign Vehicle To Booking</h4>
            </div>
            <div class="modal-body">
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 col-form-label text-md-right">Vehicle</label>
                <div class="col-md-6">
                  <!-- <select
                    class="ml-2 mr-2 pr-2 btn btn-light btn-xs selallvehicles"
                    @change="onChange($event)"
                  >
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETALLVEHICLES"
                                  :value="item.id"
                                >{{item.licence_number}}</option>
                  </select> -->
                </div>
              </div>
              <!-- End form-group -->
            </div>
            <div class="modal-footer">
              <div class="text-center">
                <button href="javascript:;" class="btn btn-white m-5" data-dismiss="modal">Close</button>
                <button
                  @click="changeVehicleBillingType(context,CHANGEBILLING)"
                  class="btn btn-primary m-5"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-dialog end -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaybookings_dashboard");

export default {
  name: "TAXIVEHICLEPROFILE",
  data: function () {
    return {
      GETDASHBOARDCARDS:[],
      GETALLRANKS:[],
      GETALLROUTES:[],
      GETALLVEHICLES:[],
      BOOKINGS:[],
      SELECTVEHICLE:null,
      SCANSUBMIT:true,
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      RANKROUTEID:null,
      STARTDATE:null,
      ENDDATE:null,
      ISLOADING: false,
      GETOPERATORFINSTATS:[],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },
  computed: {
    ...mapGetters(["GETVEHICLEROUTES"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getallbookingslots",
      "GetAllRanksByTaxiAssId",
      "GetRoutesByRankId",
      'getbookingsotsdashboardcards',
      "GetAllVehiclesById",
      "AssignVehicleToBooking",
    ]),
    getupdatedbookings: (context) => {
      const router = context.$router;

      var payload = {
        taxiassociationid: context.taxiassociationid,
        startdate:context.STARTDATE,
        enddate:context.ENDDATE,
        rankrouteid:context.RANKROUTEID,
      };

      context.ISLOADING = true;

      context.getallbookingslots({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        

        context.BOOKINGS = parsedResultOP.Data;

        context.ISLOADING = false;

        //handleOperatorByGender();
      })
    },
    getallbookings: (context) => {

      
      
      const router = context.$router;

      var payload = {
        taxiassociation_id: context.taxiassociationid,
        startdate:null,
        enddate:null,
        rankrouteid:null,
      };

      context.ISLOADING = true;
      

      context.getallbookingslots({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        context.ISLOADING = false;

        context.BOOKINGS = parsedResultOP;

        //handleOperatorByGender();
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
            
            var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

 	                  $("#bookingsslots").DataTable(options);

          });
    },
    getallbookingscards: (context) => {

      const router = context.$router;

    var payload = {
      taxiassociationid : context.taxiassociationid
    };

    //  var payload = {
    //   taxiassociationid : 1,
    // };
      
      context.getbookingsotsdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;

      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      

      context.GetAllVehiclesById({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");
        context.GETALLVEHICLES = parsedResultOP;
      }).catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        $("#selallvehicles").select2({
          multiple: false,
          placeholder: "Select Vehicles",
          allowClear: false,
        });

      });

      context.GetAllRanksByTaxiAssId({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETALLRANKS = parsedResultOP.Data;

        //handleOperatorByGender();
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
       
        $("#selallranks").select2({
          multiple: false,
          placeholder: "Select A Rank",
          allowClear: false,
        }).on('change', function(){
          // ...
          //alert($("#selallranks").select2("data")[0])
          
          const context = $(document).data("context");

          var payload = {
            id: $("#selallranks").select2("data")[0].id,
          };

      var router = context.router;

      context.GetRoutesByRankId({ router, payload })
      .then(parsedResultOP => {
        context.GETALLROUTES = parsedResultOP;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        $("#selallroutes").select2({
          multiple: false,
          placeholder: "Select Destination",
          allowClear: false,
        }).on('change', function(){
          // ...
          //alert($("#selallranks").select2("data")[0])
          
          const context = $(document).data("context");

var router = context.router;



var payload = {
  startdate:context.STARTDATE,
  enddate:context.STARTDATE,
  rankrouteid:$("#selallroutes").select2("data")[0].id,
};

context.ISLOADING = true;

context.getallbookingslots({ router, payload })
.then(parsedResultOP => {
  //const context = $(document).data("context");

  context.ISLOADING = false;

  context.BOOKINGS = parsedResultOP;

})
.catch(error => {
  //context.$wait.end("generatestatement");
}).finally(() => {
});

        });

      });

        });
        
      });
    },
    onChangeVehicle(context,event,item) {

      context.$Notify.YesNo(false, { context,item, selectvehicle:event.target.value }).then((value) => {
        
        //value.context.$wait.start("verifyUserPin");
        if (value) {
          //value.router = value.context.$router;
          //vin_number
          var router = value.context.$router;

          var AssignedFullName = value.context.LoggedInUserFullName

          var payload = {
            AssignedFullName,
            BookingSlotId:value.item.id,
            VinNumber:value.selectvehicle,
            AssignedBy: value.context.TPAYPROFILE.SystemUsersId,
            GeoLocation:"",
          }

            context.AssignVehicleToBooking({ router, payload })
            .then(parsedResultOP => {
              //context.GETALLROUTES = parsedResultOP;
              context.getupdatedbookings(context);
              context.$Notify.Success("Successfully!", "");
            })
            .catch(error => {
              //context.$wait.end("generatestatement");
            }).finally(() => {

            });

        }
      });

    },
    onChange(event) {

      const context = $(document).data("context");

      //context.ROUTEID = event.target.value;

      // setInterval(function() {
      // your code goes here...

      var payload = {
        id: event.target.value,
      };

      var router = context.router;

      context.GetRoutesByRankId({ router, payload })
      .then(parsedResultOP => {
        context.GETALLROUTES = parsedResultOP;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        $("#selallroutes").select2({
          multiple: false,
          placeholder: "Select Distination",
          allowClear: false,
        });

      });

      // }, 60 * 1000); // 60 * 1000 milsec

    },
  },
  beforeMount() {
    // this.show(this.context, true);
    // this.getbyid(this.context, this.id);
  },
  mounted: function () {
    //this.get(this.context
    App.init();

    $(document).data("context",this);

    


    this.getallbookingscards(this.context);
    this.getallbookings(this.context);

    let payload = {
      taxiassociationid: this.taxiassociationid
    };

    this.$store.dispatch('tpaybookings_dashboard/getoperatorfinstatsbyId',{ router:this.router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETOPERATORFINSTATS = parsedResultOP;

    // handleOperatorByAgeGroup();
		// handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart(parsedResultOP[0]);
    // handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        const context = $(document).data("context");
      });

    var handleTotalOpPerTaxiAssBarChart = function (json_data) {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

    //var json_data = context.GETOPERATORFINSTATS;

  var data_dep = [];
  var data_pay = [];
  var data_30days = [];

   

  for(var i in json_data.days){
    data_dep.push(json_data.totalupcoming[i].totalupcoming);
    data_pay.push(json_data.totalwaitinglist[i].totalwaitinglist);
    data_30days.push(json_data.days[i].days);
  }
    
//     var data_dep =  [
//     23,
//     211,
//     147,
//     24,
//     63,
//     66,
//     20,
//     298,
//     27,
//     40,
//     323,
//     142,
//     671,
//     56,
//     119,
//     65,
//     191,
//     30,
//     49,
//     103,
//     36,
//     27,
//     34,
//     29,
//     90,
//     29,
//     27,
//     34,
//     29,
//     90,
//     29,
// ];

    
//      var data_pay =  [
//     32,
//     363,
//     303,
//     32,
//     140,
//     131,
//     46,
//     542,
//     43,
//     88,
//     853,
//     306,
//     1510,
//     229,
//     450,
//     108,
//     471,
//     56,
//     80,
//     282,
//     91,
//     49,
//     74,
//     131,
//     381,
//     54,
//     49,
//     74,
//     131,
//     381,
//     54
// ]

       

//         var data_30days =  [
//           '1 Jul','2 Jul','3 Jul','4 Jul','4 Jul','5 Jul','6 Jul','7 Jul','8 Jul','9 Jul','10 Jul',
//           '11 Jul','12 Jul','13 Jul','14 Jul','15 Jul','16 Jul','17 Jul','18 Jul','19 Jul','20 Jul','21 Jul',
//           '22 Jul','23 Jul','24 Jul','25 Jul','26 Jul','27 Jul','28 Jul','29 Jul','30 Jul','31 Jul',
//         ]

          
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Daily Booking vs Waiting List',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_GREEN,COLOR_RED],
		series: [{
			name: 'Upcoming Bookings',
			data: data_dep
		}, {
			name: 'Waiting Lists',
			data: data_pay
		}],
		xaxis: {
			categories: data_30days,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: '$ (thousands)'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};
    
    //this.getallupcomingbookings(this.context);

     $("#advance-daterange span").html(
      moment().format("D MMMM YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(1, "days"),
        endDate: moment(),
        minDate: "01/01/2015",
        maxDate: "12/31/2099",
        dateLimit: { days: 365 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        

        const context = $(document).data("context");

        context.STARTDATE = start.format("D MMMM YYYY");
        context.ENDDATE = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );
        
      }
    );
   
  },
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>