<template>
  <!-- begin #page-container -->
  <div id="page-container" class="fade">
    <!-- begin login -->
    <div class="login login-with-news-feed">
      <!-- begin news-feed -->
      <div class="news-feed">
        <div
          class="news-image"
          style="background-image: url(../assets/img/login-bg/taxirank.jpg)"
        ></div>
        <div class="news-caption">
          <h4 class="caption-title">
            <b>Taxi Association Management System</b>
          </h4>
          <p>Effective , Efficient and Easy</p>
        </div>
      </div>
      <!-- end news-feed -->
      <!-- begin right-content -->
      <div class="right-content bg-white">
        <!-- begin login-header -->
        <div class="login-header">
          <div class="brand">
            <span class="logo">
              <img src="/assets/img/logo/logo.png" />
            </span>
            <b></b>
            <small></small>
          </div>
          <br />
          <br />
          <br />
          <div class="icon">
            <i class="fa fa-sign-in"></i>
          </div>
        </div>
        <!-- end login-header -->

        <!-- begin login-component -->
        <!-- begin login-content -->
        <div class="login-content">
            <div action="#" class="margin-bottom-0">
              <div class="form-group m-b-15">
                <label for="exampleInputEmail1">Username/ID Number</label>
                <input
                  type="text"
                  class="form-control"
                  name="UserName"
                  id="UserName"
                  v-model="LoginModel.username"
                  placeholder="UserName"
                  required
                />
              </div>
              <div class="form-group m-b-15">
                <label for="exampleInputPassword1">Password</label>
                <div class="col-md-15">
                  <input
                    data-toggle="password"
                    data-placement="after"
                    class="form-control"
                    v-model="LoginModel.password"
                    type="password"
                    placeholder="password"
                    v-on:keyup.13="login"
                  />
                </div>
              </div>
              <hr />
              <div class="login-buttons">
                <button 
                data-sitekey="6Lcayo8gAAAAAHg-PlSHeg2wcuaZB7gqQPbPjG0I" 
                data-callback='onSubmitLogin' 
                data-action='submit'
                 :disabled="$wait.is('LoggingIn')"
                 class="g-recaptcha btn btn-success btn-block btn-lg"
                >
                 <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Login
                  </v-wait></button>

                <button
                  ref="login"
                  type="submit"
                  @click="login"
                  id="btnLogin"
                  class="btn btn-success btn-block btn-lg"
                  :disabled="$wait.is('LoggingIn')"
                  hidden
                >
                  <v-wait for="LoggingIn">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Login
                  </v-wait>
                </button>
              </div>
              <div class="m-t-20 p-b-10 text-inverse">
                <a href="#forgotpasswordModal" data-toggle="modal"
                  >Forgot Password</a
                >
              </div>
              <!-- animated -->
              <div
                class="
                  progress
                  rounded-corner
                  width-full
                  progress-striped
                  active
                "
                v-show="SHOWPROGRESS.PROGRESS > 0"
              >
                <div
                  class="progress-bar bg-green text-center"
                  :style="{ width: SHOWPROGRESS.PROGRESS + '%' }"
                ></div>
              </div>

              <br />

              <div
                v-for="item in LOGINERRORMESSAGE"
                v-bind:key="item.message"
                class="alert alert-danger fade show"
              >
                <strong>Login Failed!</strong>
                <div>{{ item.message }}</div>
              </div>

              <div class="m-t-20 m-b-40 p-b-40 text-inverse">
                
              </div>

              <hr />
              <p class="text-center text-grey-darker">
                Enquiries Call 087 236 3065
              </p>
            </div>
            *
        </div>
        <!-- end login-content -->

        <!-- #forgotpasswordModal -->
        <div class="modal fade" id="forgotpasswordModal" data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="text-center">Password Reset</h4>
              </div>
              <div class="modal-body">
                <!-- begin form-group -->
                <div class="form-group row m-b-10">
                  <label class="col-md-4 col-form-label text-md-right">
                    ID Number:
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="id_number"
                    />
                  </div>
                </div>
                <!-- end form-group -->
              </div>
              <div class="modal-footer">
                 

                <a
                  href="javascript:;"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  >Close</a
                >
                <button
                  type="submit"
                  @click="resetPassword(context, id_number)"
                  id="btnLogin"
                  class="btn btn-success"
                  :disabled="$wait.is('resetPassword')"
                >
                  <v-wait for="resetPassword">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Request
                  </v-wait>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- #forgotpasswordModal end -->

        <!-- #OTP Modal -->
        <div class="modal fade" id="OTPModal" data-keyboard="false" data-backdrop="static" data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-body">
                <p class="modal-title">One Time Verification</p>
                <br />
                <!-- begin form-group -->
                <div class="form-group row m-b-10">
                  <label class="col-md-4 col-form-label text-md-right">
                    OTP Number:
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="VERIFIY.one_time_pin"
                    />
                  </div>
                </div>
                <!-- end form-group -->
              </div>
              <div class="modal-footer text-center">
                <a
                  href="javascript:;"
                  class="btn btn-default m-r-5"
                  data-dismiss="modal"
                  >CLOSE</a
                >
                <a
                  href="javascript:;"
                  @click="requestNewPin(context)"
                  class="btn btn-warning m-r-5"
                  >RESEND OTP</a
                >

                <button
                  type="button"
                  @click="verifyUserPin(context, VERIFIY)"
                  class="btn btn-success m-r-5"
                  :disabled="$wait.is('verifyUserPin')"
                >
                  <i
                    class="fa fa-search fa-fw"
                    v-show="!$wait.is('verifyUserPin')"
                  ></i>
                  <i
                    class="
                      fa fa-spinner fa-spin
                      t-plus-1
                      text-white
                      fa-fw fa-lg
                    "
                    v-show="$wait.is('verifyUserPin')"
                  ></i>
                  VERIFY
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- #OTP Modal end -->

        <!-- end login-component -->
      </div>
      <!-- end right-container -->
    </div>
    <!-- end login -->
  </div>
  <!-- end page container -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
//import { mapGetters, mapActions } from 'vuex'
import { mapFields } from "vuex-map-fields"; // https://github.com/maoberlehner/vuex-map-fields#namespaced-vuex-modules

const { mapGetters, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "Login",
  components: {
    //LoginComp
  },
  data() {
    return {
      id_number: "",
      LoginModel: {
        username: "",
        password: "",
        webpushtoken: "test",
      },
      VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
      verificationMessage: "",
      unknownBrowser: false,
      OTPSENT: true,
      context: this,
      HASWEBTOKEN: this.$store.getters["auth/GETWEBTOKEN"],
      LOGINERRORMESSAGE: [],
    };
  },
  computed: {
    ...mapGetters([
      "LOGINOBJECT",
      "LOGINFAILED",
      "SHOWPROGRESS",
      "GETWEBTOKEN",
    ]),
    ...mapFields(["loginmodel.username", "loginmodel.password"]),
  },
  beforeMount: () => {
    App.init();
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  methods: {
    ...mapActions([
      "Auth_login",
      "clearprogress",
      "updatewebtoken",
      "systemUserVerifyPin",
    ]),
    login() {
      //this.$wait.start('LoggingIn');
      //this.$router.push('sysadmin_dashboard')
      this.LOGINERRORMESSAGE = [];

      localStorage.removeItem("vuex");

      this.$wait.start("LoggingIn");
      this.Auth_login({
        _loginmodel: this.LoginModel,
        router: this.$router,
        context: this.context,
      })
        .then(({ rb, context }) => {
          console.log("login results: ", rb);

          //       // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet
          //       // To set unique user id in your system when it is available
          //       /* COMMENTED OUT TEMPORARY TILL GO-LIVE */
          //       
          //window.fcWidget.setExternalId(rb.system_usersid);
          // // To set user name
          //window.fcWidget.user.setFirstName(rb.first_name + " " + rb.last_name);

         

        })
        .catch((rb) => {
          

          if (!rb.status) {
            this.LOGINERRORMESSAGE = Array.isArray(rb.data)
              ? rb.data
              : [rb.data];
          }

          if (!rb[0].knownbrowser) {
            this.VERIFIY.user_id = rb[0].system_usersid;
            this.VERIFIY.webpushtoken = this.LoginModel.webpushtoken;

            this.unknownBrowser = true;

            var numberLastFour = rb[0].mobile_number;
            var lastFour = numberLastFour.substr(numberLastFour.length - 4);

            var message = `Enter the one-time password (OTP) that has been sent to your cellphone 27******${lastFour}`;
            $("#OTPModal").find(".modal-title").text(message);
            this.verificationMessage = message;

            $("#OTPModal").modal("show");

            this.OTPSENT = false;

          }
        })
        .finally(() => {
          this.$wait.end("LoggingIn");
        });
    },

    verifyUserPin: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        value.context.$wait.start("verifyUserPin");
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("auth/systemUserVerifyPin", {
              addModel: value.item,
              router: value.router,
              context,
            })
            .then((data) => {
              
              console.log("user has been activated", data);

              value.context.$wait.end("verifyUserPin");

              //$('#btnLogin').click();
              $("#OTPModal").modal("hide");
              value.context.login();
            })
            .catch((error) => {
              

              console.log("activate user errors: ", error);
              value.context.$Notify.Error(error.data.message, "");

              this.VERIFIY.one_time_pin = "";
              value.context.$wait.end("verifyUserPin");
            })
            .finally(() => {});
        }
      });
    },

    requestNewPin: (context) => {
      context.login();
    },

    resetPassword: (context, item) => {
      

      context.id_number = item;
      if (context.id_number.length === 13) {
        context.$Notify.YesNo(false, { context, item }).then((value) => {
          
          
          if (value) {
            value.context.$wait.start("resetPassword");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/forgotUserPassword", {
                id: value.item,
                router: value.router,
              })
              .then((data) => {
                console.log("user has been activated", data);

                $("#forgotpasswordModal").modal("hide");
                context.id_number = "";
                value.context.$Notify.Success(
                  "Password Has Been Sent To Mobile Number Successfully!",
                  ""
                );
              })
              .catch((error) => {
                console.log("activate user errors: ", error);
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                value.context.$wait.end("resetPassword");
              });
          }
        });
      } else {
        context.$Notify.Error("Please enter valid ID number");
      }
    },
  },
  mounted() {
    App.init();
    this.clearprogress();

    this.$wait.start("LoggingIn");

    $(document).data("context", this.context);

    $(document).ready(function () {
      const context = $(document).data("context");

      PushManager.init(context)
        .then((result) => {
          context.webpushtoken = result;
          context.$wait.end("LoggingIn");
        })
        .catch((err) => {
          this.LOGINERRORMESSAGE = err;
          context.$wait.end("LoggingIn");
        });
    });
    
  },
};
</script>

<style scoped>
</style>

