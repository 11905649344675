<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'TakePayment'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Take Payment"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Take Payment"
        ActiveSubMenu="Money"
        ActiveMenu="TakePayment"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-Logo -->
        <span class="pull-right">
          <img :src="encodedImage" class="width-160 height-40" />
        </span>
        <!-- begin invoice-Logo -->
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createpayment')"
              @click="createpayment(context)"
              :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('createpayment'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createpayment')
            }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createpayment')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createpayment')"
              ></i>

              <span>Take Payment</span>
            </a>
            <a
              v-show="printReady"
              href="#modal-dialog"
              data-toggle="modal"
              class="btn m-r-20 btn-lg btn-white m-b-10 p-l-5"
            >
              <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
            </a>
          </span>
          {{USERPROFILE.taxi_associations_name}}

           <div class="spinner-border spinner-border-lg text-info" v-if="!dataloadedABC"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
        </div>
        <!-- end invoice-company -->

        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="paymentCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Payment Successfully.
          </div>

          <div class="row">
            <div class="col-lg-6">
              <!-- begin card -->
              <div class="card text-center">
                <div class="card-header">
                  <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account">
                      <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Taxis</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_operator_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-2">Operators</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_driver_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-3">Drivers</a>
                    </li>
                  </ul>
                </div>
                <div class="card-block">
                  <div class="tab-content p-0 m-0">
                    <div
                      class="tab-pane fade active show"
                      id="card-tab-1"
                      v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxi default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                          <option value="0">Select A Taxi</option>
                            <option
                              v-for="item in GETALLTAXIS"
                              :key="'T'+item._taxi_operator_vehicles_id"
                              :value="item._taxi_operator_vehicles_id"
                            >{{item._licence_plate}} ({{item._taxi_operators_name}} Bal: R{{item._available_balance || 0}})</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-2"
                      v-show="SELECTEDTAXIASSOCIATION._create_operator_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectOperator default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option value="0">Select An Operator</option>
                            <option
                              v-for="item in GETALLOPERATORS"
                              :key="'O'+item.taxi_operator_id"
                              :value="item.taxi_operator_id"
                            >{{item.firstname}} {{item.surname}}({{item.id_number}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-3"
                      v-show="SELECTEDTAXIASSOCIATION._create_driver_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxiDrivers default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                          <option value="0">Select A Driver</option>
                            <option
                              v-for="item in GETAllTAXIDRIVERS"
                              :key="'D'+item._taxi_drivers_id"
                              :value="item._taxi_drivers_id"
                            >{{item._firstname}} {{item._surname}}({{item._id_number}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </div>

            <div class="col-lg-6">
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-25 m-l-40 p-r-40 width-200 pull-left">
                  <label class="pull-left m-l-10">Captured By:</label>
                  <select
                    class="form-control width-150 m-l-40 p-r-40 p-t-10 text-left"
                    data-size="10"
                    data-live-search="true"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    id="selectcapturedby"
                  >
                    <option value="0">Select Captured By</option>
                    <option
                      v-bind:key="'C'+item.id"
                      v-for="item in CAPTUREDBY"
                      :value="item.id"
                    >{{item.firstname}} {{item.surname}}</option>
                  </select>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-10 m-l-40 p-l-20 width-200 pull-right">
                  <label class="p-t-20">Payment Date:</label>
                  <div
                    class="input-group date"
                    id="datepicker-disabled-past"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Select Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>
              <br />
              <!-- begin widget-list -->
              <div
                v-if="VEHICLETRANSACTIONS.length > 0"
                class="widget-list widget-list-rounded m-b-30"
              >
                <!-- begin widget-list-item -->
                <div class="widget-list-item" :key="'VT'+item._id" v-for="item in VEHICLETRANSACTIONS">
                  <div class="widget-list-content">
                    <h4
                      class="widget-list-title"
                    >Amount: {{item._transaction_amount != null ? item._transaction_amount : 0}}</h4>
                    <p class="widget-list-desc">
                      <b>Details:</b>
                      {{item._transaction_reference != null ? item._transaction_reference : 'N/A'}}
                    </p>
                  </div>
                  <div class="widget-list-action">
                    <h4 class="widget-list-title">{{item._captured_date | formatToLongDateTime }}</h4>
                  </div>
                </div>
                <!-- end widget-list-item -->
              </div>

              <!-- end widget-list -->
              <div
                v-if="OPERATORTRANSACTIONS.length > 0"
                class="widget-list widget-list-rounded m-b-30"
              >
                <!-- begin widget-list-item -->
                <div class="widget-list-item" :key="'OT'+item._id" v-for="item in OPERATORTRANSACTIONS">
                  <div class="widget-list-content">
                    <h4
                      class="widget-list-title"
                    >Amount: {{item._transaction_amount != null ? item._transaction_amount : 0}}</h4>
                    <p class="widget-list-desc">
                      <b>Details:</b>
                      {{item._transaction_reference != null ? item._transaction_reference : 'N/A'}}
                    </p>
                  </div>
                  <div class="widget-list-action">
                    <h4
                      class="widget-list-title"
                    >{{item._captured_date | formatToLongDateTime != null ? item._captured_date | formatToLongDateTime : item_date_added | formatToLongDateTime}}</h4>
                  </div>
                </div>
                <!-- end widget-list-item -->
              </div>
              <!-- end widget-list -->

              <!-- end widget-list -->
              <div
                v-if="DRIVERTRANSACTIONS.length > 0"
                class="widget-list widget-list-rounded m-b-30"
              >
                <!-- begin widget-list-item -->
                <div class="widget-list-item" :key="'DT'+item._id" v-for="item in DRIVERTRANSACTIONS">
                  <div class="widget-list-content">
                    <h4
                      class="widget-list-title"
                    >Amount: {{item._transaction_amount != null ? item._transaction_amount : 0}}</h4>
                    <p class="widget-list-desc">
                      <b>Details:</b>
                      {{item._transaction_reference != null ? item._transaction_reference : 'N/A'}}
                    </p>
                  </div>
                  <div class="widget-list-action">
                    <h4
                      class="widget-list-title"
                    >{{item._captured_date | formatToLongDateTime != null ? item._captured_date | formatToLongDateTime : item_date_added | formatToLongDateTime}}</h4>
                  </div>
                </div>
                <!-- end widget-list-item -->
              </div>
              <!-- end widget-list -->
            </div>
          </div>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin invoice-price -->
          <div class="invoice-price">
            <div class="invoice-price-left">
              <div class="invoice-price-row">
                <div class="sub-price">
                  <small>Cash</small>

                  <span class="text-inverse">
                    <CurrencyInput
                      id="cash"
                      v-model="TOTALINVOICEAMOUNT"
                      :currency="currency"
                      :auto-decimal-mode="false"
                      class="height-40 text-left demo__currency-input"
                    />
                  </span>
                </div>
                <div class="sub-price">
                  <i class="fa fa-plus text-muted"></i>
                </div>
                <div class="sub-price">
                  <small>TRANSACTION FEE ({{this.TRANSFEE}}%)</small>
                  <span class="text-inverse">R{{TRANSACTIONFEE | currency}}</span>
                </div>
              </div>
            </div>
            <div class="invoice-price-right">
              <small>TOTAL</small>
              <span class="f-w-600">R{{TOTALMOUNT | currency}}</span>
            </div>
          </div>
          <!-- end invoice-price -->
        </div>
        <!-- end invoice-content -->
        <!-- begin invoice-note -->
        <div class="invoice-note">
          <div class="form-group row">
            <label class="col-md-1 col-form-label">Reference</label>
            <div class="col-md-5">
              <textarea
                v-model="COMMENT"
                class="form-control m-b-10"
                id="clipboard-textarea"
                rows="7"
              ></textarea>
            </div>
          </div>
          * Make all invoices payable to {{USERPROFILE.taxi_associations_name}}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{SELECTEDTAXIASSOCIATION._telephone}}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- #modal-dialog -->
    <div class="modal fade" id="modal-dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Print Receipts</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <table id="data-table-titles" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="text-nowrap">Type</th>
                  <th class="text-nowrap">Name</th>
                  <th class="text-nowrap">Receipt Number</th>
                  <th class="text-nowrap">
                    <a
                      @click="printAll(context)"
                      href="javascript:;"
                      data-dismiss="modal"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print All
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody v-if="GETPAYMENTRESULTS.length > 0">
                <tr
                  v-for="(item,index) in GETPAYMENTRESULTS"
                  v-bind:key="'PR'+item._id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.entityname}}</td>
                  <td>{{PAYMENTRESULTS_NAME(context,item.entityname,item.entity_id)}}</td>
                  <td>{{item.receiptnumber}}</td>
                  <td class="with-btn valign-middle" nowrap>
                    <a
                      @click="printNow(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print
                    </a>
                    <a
                      @click="printPreview(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Preview
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Close</a>
          </div>
        </div>
      </div>
    </div>
    <!-- #modal-dialog -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import Inputmask from "inputmask";
import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "TakePayment",
  data: function () {
    return {
      currencySymbol: "",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      TRANSFEE: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._percentage,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLTAXIS: [],
      GETALLOPERATORS: [],
      GETAllTAXIDRIVERS: [],
      skip: 0,
      len: 3,
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      VEHICLETRANSACTIONS: [],
      OPERATORTRANSACTIONS: [],
      DRIVERTRANSACTIONS: [],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      encodedImage: undefined,
      context: this,
      COMMENT: "",
      CAPTUREDBY: [],
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      paymentCreated: false,
      dataloadedABC: false,
      printReady: false,
      linetotal: 0,
      SELECTEDTAXIID: 0,
      TOTALINVOICEAMOUNT: "",
      vehicleAccountInfo: [],
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0,
        },
      ],
      PREPAYMENTOBJECT: this.$store.getters["payments/GETPREPAYMENTDETAILS"],
      PrePayment: this.$route.params.PrePayment,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
   
    
    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });
  },
  beforeDestroy: function () {
    this.clearAll(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["GETPAYMENTRESULTS"]),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function () {
      return Date();
    },
    PAYMENTAMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT);
    },
    TRANSACTIONFEE: function () {
      let _transfee = 0;
      return (Number(this.TOTALINVOICEAMOUNT) * this.TRANSFEE) / 100;
    },
    TOTALMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT) + this.TRANSACTIONFEE;
    },
  },
  watch: {
    SELECTEDTAXIID: function (val) {
      alert(val);
      if (val != null) {
        console.log("selected value: ", val);
      }
    },
  },
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "prepayment", "makepayment"]),
    reloadInfo: function (context) {
      context.getAllTaxiByTaxiAssId(context, context.taxiassociation_id);
      context.getAllOperatorByTaxiAssId(context, context.taxiassociation_id);
      context.getAllTaxiDriversByTaxiAssId(context, context.taxiassociation_id);
    },

    printNow: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: false },
      });
      window.open(routeData.href, "_blank");
    },
    printPreview: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: true },
      });
      window.open(routeData.href, "_blank");
    },
    printAll: function (context) {
      context.GETPAYMENTRESULTS.forEach((item) => {
        let routeData = context.$router.resolve({
          name: "printpayment",
          params: { id: item.receiptid, preview: false },
        });
        window.open(routeData.href, item.receiptid);
      });
    },
    reset: function (context) {
      $(".default-selectTaxiDrivers").val(null).trigger("change");
      $(".default-selectTaxi").val(null).trigger("change");
      $(".default-selectOperator").val(null).trigger("change");
      context.COMMENT = "";
      context.TOTALINVOICEAMOUNT = "";
    },

    getAllTaxiByTaxiAssId: (context, id) => {
      context.dataloadedABC = false;
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: id,
          skip: 0,
          len: 2000,
        })
        .then((data) => {

          console.log("vehicles vue: ", data);
          context.GETALLTAXIS = data;

          context.dataloadedABC = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").on("change", function () {
            var taxiID = $(".default-selectTaxi option:selected").val();
            console.log("selected Taxi", taxiID);
            if (taxiID != null) {
              var context = $(".nav-link").data("context");
              context.getvehicleTransactionsById(context, taxiID);
            } else {
              console.log("no taxi selected!");
            }
          });
        });
    },
    getAllOperatorByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByAccountNo", {
          router: context.$router,
          id,
        })
        .then((data) => {
          console.log("operators vue page: ", data);
          context.GETALLOPERATORS = data;
          //context.GETAllTAXIDRIVERS = this.$store.getters["TaxiDrivers/GETADDTAXIDRIVER"];

          $(".default-selectOperator").select2({
            multiple: false,
            placeholder: "Select A Operator",
            allowClear: false,
          });

          $(".default-selectOperator").on("change", function () {
            var operatorID = $(".default-selectOperator option:selected").val();
            console.log("selected Operator", operatorID);
            if (operatorID != null) {
              context.getOperatorTransactionsById(context, operatorID);
            } else {
              console.log("no Operator selected!");
            }
          });
        })
        .catch((error) => {
          console.log("operators vue page: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    clearAll: (context) => {
      context.$store.dispatch("payments/clearAll");
    },

    getAllTaxiDriversByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id,
        })
        .then((data) => {
          console.log("drivers vue: ", data);
          context.GETAllTAXIDRIVERS = data;
          //context.GETALLTAXIS = context.$store.getters["TV/GETVEHICLE"];

          $(".default-selectTaxiDrivers").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxiDrivers").on("change", function () {
            var DriverID = $(
              ".default-selectTaxiDrivers option:selected"
            ).val();
            console.log("selected Driver", DriverID);
            if (DriverID != null) {
              context.getdriverTransactionsById(context, DriverID);
            } else {
              console.log("no Driver selected!");
            }
          });
        })
        .catch((error) => {
          console.log("drivers errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    PAYMENTRESULTS_NAME: function (context, entityname, id) {
      if (
        entityname != undefined &&
        entityname.toUpperCase() === "Driver".toUpperCase()
      ) {
        if (context.GETAllTAXIDRIVERS.length > 0) {
          const _details = context.GETAllTAXIDRIVERS.filter(
            (item) => item._taxi_drivers_id === id
          )[0];
          console.log(_details._firstname + " " + _details._surname);
          return _details._firstname + " " + _details._surname;
        }
      } else if (
        entityname != undefined &&
        entityname.toUpperCase() === "Taxi".toUpperCase()
      ) {
        if (context.GETALLTAXIS.length > 0) {
          return context.GETALLTAXIS.filter(
            (item) => item._taxi_operator_vehicles_id === id
          )[0]._vehicle_register_number;
        }
      } else if (
        entityname != undefined &&
        entityname.toUpperCase() === "Operator".toUpperCase()
      ) {
        if (context.GETALLOPERATORS.length > 0) {
          const _details = context.GETALLOPERATORS.filter(
            (item) => item.taxi_operator_id === id
          )[0];
          console.log(_details.firstname + " " + _details.surname);
          return _details.firstname + " " + _details.surname;
        }
      }
    },
    generatePaymentResults: function (context) {},
    changeBillItem: function (context, rowitem, billingitem) {
      rowitem.billingitems_id = billingitem._billing_items_id;
      rowitem.lineamount = billingitem._billing_items_amount;
    },
    AddNewBillItem: function (context) {
      context.BILLINGITEMS.push({
        billingitems_id: 0,
        lineamount: 0,
        lineqty: 1,
        linetotal: 0,
      });
    },
    removeBillItem: function (context, index) {
      context.BILLINGITEMS.splice(index, 1);
    },
    GETTAXISBYMODELID: function (context, id) {
      return context.GETALLTAXIS.filter((item) => item._vehiclemodel_id === id);
    },
    GETTAXISBYMODELID: function (context, id) {
      return context.GETALLTAXIS.filter((item) => item._vehiclemodel_id === id);
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getAllBillingItemsByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("billingitems/getAllByTaxiAssID", {
          router: context.$router,
          id,
        })
        .then(() => {})
        .catch((error) => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getvehicleTransactionsById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleTransactionsById", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicles transactions results: ", data);
          context.VEHICLETRANSACTIONS = data;
          context.OPERATORTRANSACTIONS = [];
          context.DRIVERTRANSACTIONS = [];
        })
        .catch((error) => {
          console.log("vehicles vue page: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getCapturedBy: (context) => {
      
      context.$store
        .dispatch("systemusers/getSystemUsersCapturedBy", {
          associationid: context.taxiassociation_id,
          userid: context.USERID,
          router: context.$router,
        })
        .then((data) => {
          console.log("captured By results: ", data);
          context.CAPTUREDBY = data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getOperatorTransactionsById: (context, id) => {
      
      context.$store
        .dispatch("TO/getOperatorTransactionsByOperatorId", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("Operator transactions results: ", data);
          context.OPERATORTRANSACTIONS = data;
          context.VEHICLETRANSACTIONS = [];
          context.DRIVERTRANSACTIONS = [];
        })
        .catch((error) => {
          console.log("vehicles vue page: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getdriverTransactionsById: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverTransactionsByDriverId", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("driver transactions results: ", data);
          context.DRIVERTRANSACTIONS = data;
          context.OPERATORTRANSACTIONS = [];
          context.VEHICLETRANSACTIONS = [];
          //context.GETAllTAXIDRIVERS = data
        })
        .catch((error) => {
          console.log("operators vue page: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    createprepayment: (context) => {
      

      try {
        if (context.PREPAYMENTOBJECT.length == 0) {
          return;
        }
      } catch (e) {}

      const paymentdetails = {
        paymentamount: context.PREPAYMENTOBJECT.SubTotal,
        transactionfee: context.PREPAYMENTOBJECT.TransactionFee,
        totalamount: context.PREPAYMENTOBJECT.TotalAmount,
      };

      let capturedBy = null;

      try {
        capturedBy = $("#selectcapturedby").select2("data")[0].id;
      } catch (e) {
        console.log("captured by error: ", capturedBy);
      }

      if (capturedBy == 0) {
        capturedBy = context.USERID;
      } else {
        capturedBy = parseInt($("#selectcapturedby").val());
      }

      const Payload = {
        paymentdetails,
        linetotal: context.PREPAYMENTOBJECT.SubTotal,
        comment: context.PREPAYMENTOBJECT.Reference.length == 0 ? 'PAYMENT' : context.PREPAYMENTOBJECT.Reference,
        reference: context.PREPAYMENTOBJECT.Reference.length == 0 ? 'PAYMENT' : context.PREPAYMENTOBJECT.Reference,
        added_by: context.USERID,
        captured_by: capturedBy,
        payment_date: context.PREPAYMENTOBJECT.PaymentDate,
        taxi_association_id: context.taxiassociation_id,
      };

      context.$Notify
        .YesNoMore("Take Payment", "Are you sure?", false, {
          Payload,
          context,
          PREPAYMENTOBJECT: context.PREPAYMENTOBJECT,
        })
        .then((value) => {
          
          if (value) {
            value.context.$wait.start("createpayment");
            value.context
              .makepayment({
                router: value.context.router,
                payload: value.Payload,
                _Operator: value.PREPAYMENTOBJECT.Operator,
                _Taxis: value.PREPAYMENTOBJECT.Taxis,
                _Drivers: value.PREPAYMENTOBJECT.Drivers,
              })
              .then((_data) => {
                console.log("take payment results: ", _data);
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.paymentCreated = true;
                value.context.printReady = true;
                value.context.prepayment({
                  router: value.context.router,
                  PrePayment: undefined,
                });
                value.context.PrePayment = false;
                value.context.PREPAYMENTOBJECT = undefined;
                value.context.$wait.end("createpayment");
                value.context.$Notify.Success("Successfully Added!", "");
              })
              .catch((error) => {
                value.context.$wait.end("createpayment");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    createpayment: (context) => {
      
      let _Operator = [];
      try {
        _Operator = $(".default-selectOperator").select2("data");
      } catch (e) {
        console.log("operator error", e);
      }
      let _Taxis = [];
      try {
        _Taxis = $(".default-selectTaxi").select2("data");
      } catch (e) {
        console.log("_Taxis error", e);
      }
      let _Drivers = [];
      try {
        _Drivers = $(".default-selectTaxiDrivers").select2("data");
      } catch (e) {
        console.log("_Taxis error", _Drivers);
      }

      if (
        _Operator.length === 0 &&
        _Taxis.length === 0 &&
        _Drivers.length === 0
      )
        return context.$Notify.Error(
          "Warning",
          "Please choose Operator, Taxi And Driver"
        );
      const paymentdetails = {
        paymentamount: context.PAYMENTAMOUNT,
        transactionfee: context.TRANSACTIONFEE,
        totalamount: context.TOTALMOUNT,
      };

      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-disabled-past").datepicker("getDate")
      );

      let capturedBy = null;

      try {
        capturedBy = $("#selectcapturedby").select2("data")[0].id;
      } catch (e) {
        console.log("captured by error: ", capturedBy);
      }

      if (capturedBy == 0) {
        capturedBy = context.USERID;
      } else {
        capturedBy = parseInt($("#selectcapturedby").val());
      }

      const Payload = {
        paymentdetails,
        linetotal: context.TOTALMOUNT,
        comment: context.COMMENT,
        reference: context.COMMENT,
        added_by: context.USERID,
        captured_by: capturedBy,
        payment_date: _converteddate,
        taxi_association_id: context.taxiassociation_id,
      };

      const Operators = [];
      const Taxis = [];
      const Drivers = [];

      _Drivers.forEach(function (_item, index) {
        Drivers.push(_item.id);
      });

      _Taxis.forEach(function (_item, index) {
        Taxis.push(_item.id);
      });

      _Operator.forEach(function (_item, index) {
        Operators.push(_item.id);
      });

      context.$Notify
        .YesNoMore("Take Payment", "Are you sure?", false, {
          Payload,
          context,
          Operators,
          Taxis,
          Drivers,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("createpayment");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("payments/makepayment", {
                router: value.router,
                payload: value.Payload,
                _Operator: value.Operators,
                _Taxis: value.Taxis,
                _Drivers: value.Drivers,
              })
              .then((_data) => {
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.paymentCreated = true;
                value.context.printReady = true;
                value.context.$wait.end("createpayment");
                value.context.$Notify.Success("Successfully Added!", "");
              })
              .catch((error) => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    this.clearAll(this.context);
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllBillingItemsByTaxiAssId(this.context, this.taxiassociation_id);
    this.getCapturedBy(this.context);
    App.init();

    // Jquery Dependency

    //Get Data
    // $('#mySelect2').select2('data');

    $(".nav-link").data("context", this.context);

    $("#datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#selectcapturedby").select2({
      multiple: false,
      placeholder: "Select Captured By",
      allowClear: false,
    });

    $("#datepicker-disabled-past").datepicker("update", new Date());

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });

    Inputmask().mask(document.querySelectorAll(".inputmask"));

    $("#cash").focus();

    if (this.PrePayment) {
      this.createprepayment(this.context);
    }

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});

  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.logo-image {
  width: 200px;
  height: 200px;
}
</style>

